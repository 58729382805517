@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.custom-bg-count-down {
  border-radius: 16px;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.border-count-down {
  border-radius: 16px;
  border: 2px solid transparent; /*2*/
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.border-custom {
  border-radius: 50px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg, #bc852b 0%, #f4d58e 58%, #bc852b 100%)
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}

.bottom-nav-active {
  background: radial-gradient(
    121.49% 277.08% at 0% 0%,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 100%;
  width: 64px;
  height: 64px;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
}

.bg-glassy-2 {
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.bg-glassy-3 {
  border-top: 1px solid;

  border-image-source: linear-gradient(
    112.83deg,
    rgba(255, 255, 255, 0.47) 0%,
    rgba(255, 255, 255, 0) 110.84%
  );
}
.bg-glassy-4 {
  background: radial-gradient(
      121.49% 277.08% at 0% 0%,
      rgba(255, 255, 255, 0.35) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.border-glossy-3 {
  border: 0.51px solid transparent; /*2*/
  background: linear-gradient(
      112.83deg,
      rgba(255, 255, 255, 0.47) 0%,
      rgba(255, 255, 255, 0) 110.84%
    )
    border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
@tailwind base;
@tailwind components;
@tailwind utilities;
